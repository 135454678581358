
/* --------------- User profile style -------------- */

.profile {
    height: 35px;
    width: 35px;
    /*border: 1px solid #707070;*/
    border-radius: 30px;
}

/* ----------- React AutoSuggest css ----------------*/
.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 100%;
    height: 37px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 12px;
    border: 1px solid #DEDEDF;
    border-radius: 100px 100px 100px 100px;
    padding: 0 20px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 100%;
    border: 1px solid #DEDEDF;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 12px;
    border-radius: 20px;
    z-index: 3;
    max-height: 200px;
    overflow: auto;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 10px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}
/* ------------------------------------*/

/* ----------- Login css ----------------*/
.background-bg{
    background-image: url(../image/ic_login_bg_new.jpg);
    background-size:cover;
    background-position-x:70%;
     background-repeat:no-repeat;
}

/* ------------------------------------*/

input[type="file"] {
    display: none;
}
/* ----------- input toggle css ----------------*/
.custom-control-input:focus{
    outline: none !important;
}
.custom-switch .custom-control-label::before{
    left: 0rem !important;
    width: 2rem !important;
    height: 1rem !important; 
    border-radius: .5rem !important;
}
 .custom-switch .custom-control-label::after {
    top: calc(.25rem + 3px) !important;
    left: calc(-0.3rem + 7px) !important;
    width: calc(.9rem - 4px) !important;
    height: calc(.9rem - 4px) !important;
}
.custom-switch .custom-control-input:checked~.custom-control-label::after{
    top: calc(.25rem + 2px) !important;
    left: calc(0.5rem + -2px) !important;
    width: calc(1rem - 4px) !important;
    height: calc(1rem - 4px) !important;
} 
.custom-switch .custom-control-input:checked~.custom-control-label::before{
    color: #fff !important;
    border-color: #499bc9 !important;
    background-color: #499bc9 !important;
}

.pure-material-switch {
    z-index: 0;
    position: relative;
    display: inline-block;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    line-height: 1.5;
}

/* Input */
.pure-material-switch > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    right: 6px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
}

/* Span */
.pure-material-switch > span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

/* Track */
.pure-material-switch > span::before {
    content: "";
    float: right;
    display: inline-block;
    margin: 5px 0 5px 10px;
    border-radius: 7px;
    width: 30px;
    height: 10px;
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    vertical-align: top;
    transition: background-color 0.2s, opacity 0.2s;
}

/* Thumb */
.pure-material-switch > span::after {
    content: "";
    position: absolute;
    top: 2px;
    right: 16px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 0.2s, transform 0.2s;
}

/* Checked */
.pure-material-switch > input:checked {
    right: -10px;
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-switch > input:checked + span::before {
    background-color: #4AC6F0 !important;
}

.pure-material-switch > input:checked + span::after {
    background-color: #1C9CC7;
    transform: translateX(16px);
}

/* Active */
.pure-material-switch > input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
}

.pure-material-switch > input:active + span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

.pure-material-switch > input:checked:active + span::before {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

/* Disabled */
.pure-material-switch > input:disabled {
    opacity: 0;
}

.pure-material-switch > input:disabled + span {
    color: rgb(var(--pure-material-onsurface-rgb, 0, 0, 0));
    cursor: default;
}

.pure-material-switch > input:disabled + span::before {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

.pure-material-switch > input:checked:disabled + span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

/* ------------------------------------*/

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  margin: 0; 
}

.table thead th {
    border-bottom: 1px solid #dee2e6 !important;
    border-top: none;
}
.view-header{
    color:#010b31;
    opacity: 0.5;
}

.validated .form-control:valid~.invalid {
    display: none;
}

.validated .custom-control :valid~.invalid {
    display: none;
}

.profile{
    border-radius: 50%;
}
.react-datepicker-popper{
    z-index: 1031 !important;
}
.react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100% !important;
}
.react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100% !important;
}

.react-datepicker{
    display:flex !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width:1.5rem !important;
}

.custom-control.custom-checkbox .custom-control-input {
    opacity: 1 !important;
    z-index: 0 !important;
    width: 15px;
    height: 15px;
}

.custom-radio .custom-control-label::before,.custom-radio .custom-control-label::after{
    top:0.1rem !important;
}

.checkmark {
     content: '';
     display: block;
     width: 6px;
     height: 12px;
     border: solid #07C220;
     border-width: 0 2px 2px 0;
     transform: rotate(45deg);
 }
.text-ellipsis {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
.disabled {
    cursor: not-allowed;
}
.b-none{
    border:none !important;
}
.h-42 {
    height: 42px;
}

.input-height {
    height: 32px !important;
}
.css-yk16xz-control,.css-1fhf3k1-control {
    min-height: 32px!important ;
}
.css-tlfecz-indicatorContainer{
    padding: 0px!important ;
}

/** modal */
.modal-backdrop.in {
    opacity: .5 !important;
}
.fade.modal {
    opacity: 1 !important;
}
.modal-header .close{
    float: right!important;
}
.inner-sidenav-margin{
    margin-right: 15PX;
}
.dialog .fade.modal-backdrop.in{
    opacity:.5!important;
}
.tab-content .fade.in{
    opacity:1 !important;
}
.nav-tabs li.active a{
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}
.nav-tabs li a {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}
.nav-tabs li {
    margin-bottom:-1px;
}
.dropdown-menu{
   right: 0;
}
.form-control{
    padding: .375rem .5rem !important;
}

.feedback-description{
    text-overflow: ellipsis;
    cursor: pointer;
    word-break: break-all;
    overflow:hidden;
    white-space: nowrap;
}
.feedback-description:hover{
    overflow: visible;
    white-space: normal;
    word-wrap: normal!important;
    height:auto; /* just added this line */
}

.dashBoard .card .react-datepicker-wrapper {
    width: 70% !important;
    color: black;
}
.dashBoard .card .react-datepicker-wrapper input:focus{
    box-shadow: none;
}
.card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 8px;
}

.dashBoard {
    font-family: 'Montserrat', sans-serif;
}
.dashBoard .form-control {
    border: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.font-size-12{
    font-size: 12px !important;
}
.font-size-13{
    font-size: 13px !important;
}
.font-size-14{
    font-size: 14px !important;
}
.font-size-15{
    font-size: 15px !important;
}
.font-size-16{
    font-size: 16px !important;
}
.font-size-20{
    font-size: 20px !important;
}
.font-weight-500{
    font-weight: 500!important;
}
.font-weight-600{
    font-weight: 600!important;
}
.dashBoard-secondary-color {
    color:#8D8D8D !important;
}

.black-color {
    color:#000000 !important;
}

.table-header{
    background: #EDEDED !important;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Monserrat',sans-serif;
}

.table tbody tr:nth-child(odd) {
    background: #F7F7F7 !important;
}

.table tbody tr:nth-child(even) {
    background: #FFFFFF;
}

.table .table-body-value{
    font-size: 13px;
    font-weight: 400;
    font-family: 'Monserrat', sans-serif;
}

.details-value{
    font-size: 13px;
    font-weight: 400;
    font-family: 'Monserrat', sans-serif;
}
.product-details{
    font-family: 'Monserrat', sans-serif;
}
.product-details .header {
    color: #8D8D8D !important;
    font-size: 12px;
}

.product-details .inner-header {
    color: #8D8D8D !important;
    font-size: 11px;
}

.product-details .header-value {
    color: #000000 !important;
    font-size: 12px;
    font-weight: 600;
}

.product-details .link {
    color:#1C9CC7 !important;
    font-weight: 600;
    font-size: 12px;
}

.key-arrow {
    transition: -webkit-transform 200ms;
    transition: transform 200ms;
    transition: transform 200ms, -webkit-transform 200ms;
}
.search-bg {
    background:#F3F3F3;
}
.notification-details-height {
    height: calc(100% - 80px);
}
.notification-details-height form {
    height: 100%;
}

.table-container {
    overflow-y: auto;
}

.table-container table {
    border-spacing: 0;
    width: 100%;
}

.ck-editor__editable{
    min-height:150px;
    max-height: 150px;
}

.app-logo-primary-color{
    color:#178db7 !important;
}
.app-logo-secondary-color{
    color:#fcbc3f !important;
}
.app-logo-third-color{
    color:#051436 !important;
}

/* Privacy Policy */
.banner-bg {
    background-image: url(../image/ic_login_bg_new.jpg);
    background-size:cover;
    background-position: center;
    background-repeat:no-repeat;
    height: 25vh;
}

.logo-ev {
    background-image: url(../image/ic_evdens_new.png);
    background-repeat:no-repeat;
    height: 100px;
}

.container-1 {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left-centered {
  position: absolute;
  top: 50%;
  left: 0%;
}

/* Validation */
.navy-blue-text-color {
    color: #182841 !important;
}

.gray-bg-color {
    background-color: #F2F2F2;
}

.text-black {
    color: #000;
}

.max-w-43 {
    max-width: 42.5% !important;
}

.cus-table td, .cus-table th {
    border-top: 1px solid #24ABD1 !important;
}

.cus-table .cus-body .cus-tr {
    background: #fff !important;
}

.cus-table-bordered {
    border: 1px solid #24ABD1;
}

.cus-table-bordered td, .cus-table-bordered th {
    border: 1px solid #24ABD1;
}

.cus-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.padding-5 {
    padding: 1rem;
}

@media only screen and (min-width: 576px) {
    .cus-container {
        max-width: 540px;
    }

    .padding-5 {
        padding: 3rem;
    }
}

.g-recaptcha {
     display:inline-block;
}

.privacy-policy .privacy-policy-content h6, label, h1 {
    color: #182841;
}

.privacy-policy .privacy-policy-content p, ul li {
    text-align: justify;
}

.color-default {
    color: #43425D !important;
}

.privacy-policy .privacy-policy-image {
    cursor: pointer;
}

.collapsed .submenu {
  height: 0;
  overflow: hidden;
  transition: max-height .5s ease;
}

.top-20 {
    top: 5%;
}

.panel-group {
  margin-top: 30px;
}

.panel-collapse {
    max-height: 246px;
    overflow: hidden;
    transition: max-height .5s ease;
}

.sub-menu {
    color: white;
    margin-left: 20px;
    background: #499bc95c;
}

.sub-menu .active{
    background-color: #fcbc3f;
}

.sub-menu-item {
    padding: 10px;
    font-color:#fff;
    font-weight: bold;
    cursor: pointer;
    text-align: left;
}

.panel-close {
    max-height: 0;
}