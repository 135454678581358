.product-image-container {
    height: 250px;
    width: 250px;
    top: 250px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
    .product-image-container {
        height: 150px;
        width: 150px;
        top: 50px;
        margin-bottom: 20px;
    }
}

tr td:first-child {
    font-weight: bold;
}