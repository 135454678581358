/**---- font------*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i,700,700i&display=swap');

/* ------------------- Color style --------------------------*/
.error {
    color: red !important;
}
.cursor-pointer{
    cursor: pointer;
}
.toaster {
    width: 250px !important;
    min-height: 60px !important;
    margin-left: auto;
    font-size: 12px;
    font-weight: bold;
}
.Toastify__toast--success {
    background: #0a790e !important;
    text-transform: uppercase !important;
}
.Toastify__toast--error {
    text-transform: uppercase !important;
}

/*---- Password Eye style ----- */

.eye {
    float: right;
    margin-top: -25px;
    cursor: pointer;
    position: relative;
}
/*------------------- Header ----------------------*/
.app-header {
    height: 50px;
    background: white;
    box-shadow: 0px 3px 6px #00000029;
    transition: all 0.9s;
}

.app-header-width {
    width: calc(100% - 50px);
    transition: all 0.9s;
}
.app-header-sidebar-width {
    width: calc(100% - 240px);
    transition: all 0.9s;
}

 /* ---------------- Multiselect Style --------------- */
 .css-yk16xz-control {
     background-color: #FFFFFF !important;
 }

.css-1uccc91-singleValue{
    font-weight: 500 !important;
}
.select__placeholder{
    font-weight: 500;
}

/* ------------------- Sidebar style -------------------------*/
    .wrapper {
        display: flex;
        align-items: stretch;
        position: relative;
    }
    #headerLogo {
        width: 240px;
        background: #FCBC3F;
        transition: all 0.9s;
    }
    #headerLogo.active {
        width: 50px;
        transition: all 0.9s;
    }
    #headerLogo.active span {
        display: none;
    }
    #sidebar {
        width: 240px;
        background: url('./assets/image/sidebarBackGrdImg.png');
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 0.9s;
    }
    #sidebar.active {
        width: 50px;
        background: url('./assets/image/sidebarBackGrdImg.png');
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 0.9s;
    }
    #sidebar.active span {
        display: none;
    }
    
    /*#sidebar ul li {
        padding: 14px 10px;
        padding-right:7px;
        color: #FFFFFF;
        cursor: pointer;
        list-style: none;
        text-align: left;
        font-size: 14px;
        width: 211px;
    }*/

    #sidebar .sidebar-item {
        padding: 14px 10px;
        padding-right:7px;
        color: #FFFFFF;
        cursor: pointer;
        list-style: none;
        text-align: left;
        font-size: 14px;
        width: 240px;
    }

    #sidebar.active ul li {
        width: 46px;
    }
    .sideNav .active  {
        background-color: #499BC9;
        color: #071334;
    }
    #toggleButton {
        right:5px;
        position: absolute;
        top: 50%;
        padding: 5px;
        color: red;
        text-align: right;
        cursor: pointer;
    }
    .main{
      flex:1 1;
      transition: all 0.9s;
      background: #FFFFFF;
        padding-top: 50px;
    }
    .inner-sidenav ul li{
      list-style: none;
      padding-left: 15px;
      height: 57px;
      background:#FFFFFF;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #E3E3E3;
      color: black;
    }
    .inner-sidenav .active{
        background-color: #499bc9;
        color: #FFFFFF;
    }
/* ---------------Custom scroll style ------------- */
    .custom-height {
        height: calc(100vh - 50px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .custom-scroll::-webkit-scrollbar {
        width: 3px;
    }
    .custom-scroll::-webkit-scrollbar-thumb {
        background: #666;
        border-radius: 20px;
    }
    .custom-scroll::-webkit-scrollbar-track {
        background: #ddd;
        border-radius: 20px;
    }
    .master-page-scroll::-webkit-scrollbar {
        width: 0px;
    }
    .master-page-sroll::-webkit-scrollbar-thumb {
        background: #f2f2f2;
        border-radius: 20px;
    }
    .master-page-sroll::-webkit-scrollbar-track {
        background: #f2f2f2;
        border-radius: 20px;
    }
/* --------------- Button color ---------------------------*/
     .button-sec-color {
         background-color: #1C9CC7 !important;
         color: white !important;
     }
     .button-grey-color {
         background-color: #C8C8C8 !important;
         color: white !important;
     }
/* ------------------- Authentication style---------------------*/
    .authentication-wrapper {
        display: flex;
        flex-basis: 100%;
        min-height: 100vh;
        width: 100%;
    }
    .authentication-inner {
        width: 100%;
    }
    .authentication-3 {
        align-items: stretch;
        justify-content: stretch;
    }
    .authentication-3 .authentication-inner {
        display: flex;
        align-items: stretch;
        flex-wrap: nowrap;
        justify-content: stretch;
    }
/* ------------ Logout style------------- */
    .dropdown-menu {
        font-size: 14px !important;
        color: #43425D !important;
        top: 85% !important;
        left: auto !important;
        padding: .4rem 0 !important;
    }
    .headerDropdown:hover {
        background: #499BC9;
        color: #FFFFFF !important;
    }
    .dropdown-list{
        border-bottom: 1px solid #f2f2f2;
        padding: 5px;
    }

/* ------------- Dashboard style ----------- */
.dashBoardHeader {
    text-align: left;
    font-size:  33px;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
}
.dashBoardBox{
    height: 170px;
    background: #FFFFFF;
    border: 1px solid #D4D4D5;
    border-radius: 4px;
    opacity: 1;
    color: #010B33;
}
.dashBoardBoxHeader{
    text-align: left;
    font-weight: Bold;
    font-size: 18px;
    border-bottom: 1px solid lightgray;
    height: 40px;
    padding-left: 20px;
    padding-top: 10px;
}
.dashBoardBoxBody{
    padding: 20px;
    height: 90px;
    font-size: 16px;
}
.dashBoardBoxFooter{
    border-top: 1px solid lightgray;
    height: 40px;
    padding-left: 20px;
    padding-top: 5px;
    font-size: 16px;
}
.sweet-loading {
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.15);
}
.css-x243cq {
    top: 50%;
    left: 50%;
    bottom: 50%;
}

/* ---------- User Permission Line Style---------- */
.dotted {
    border-left: 2px;
    border-left-style: solid;
    border-left-color: #499BC9;
}
.wrapper {
    display: flex !important;
    align-items: center;
}

.line {
    border-top: 2px solid #499BC9;
    width: 10px;
}

.transform {
    text-transform: lowercase;
}
.transform::first-letter {
    text-transform: uppercase;
}

.btn-app-primary {
    color: #FFFFFF !important;
    background: #FCBC3F !important;
    box-shadow: 0px 3px 6px #00000029;
    font-weight: 600 !important;
}
.btn-app-secondary {
    background: #FFFFFF !important;
    color: #FCBC3F !important;
    box-shadow: 0px 3px 6px #00000029;
    font-weight: 600 !important;
}

.btn-order-primary {
    background: #FCBC3F;
    color: white;
    borderRadius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #FCBC3F;
    outline: none;
}

.btn-order-secondary {
    background: #499bc9;
    color: white;
    borderRadius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #499bc9;
    outline: none;
}


.zoom-container {
    position: relative;
    width: 100%;
}

.image {
    max-height: 176px;
    max-width: 100%;
}

.zoom-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: .3s ease;
  background-color: rgb(0 0 0 / 36%);
}

.zoom-icon {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.zoom-container .zoom-icon .fa-user:hover {
  color: #fff;
}

