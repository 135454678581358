body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px !important;
  color:#43425D !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,body, #root {
  height: 100%;
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control {
  background-color: #FFFFFF !important;
  font-size: 12px !important;
  border: 1px solid #CFCFCF !important;
  color: #333333 !important;
  font-weight: 500 !important;
}
 select:invalid {
   color: #8D8D8D !important;
   font-weight: normal;
 }
.select-option {
  color: #333333 !important;
  font-weight: 500 !important;
}
.btn {
  font-size: 12px !important;
}
